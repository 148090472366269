import React from 'react';

const InfoBar = () => {
  return (
    <div className='w-[100%] bg-[#d81f47] py-2 flex justify-center md:block hidden'>
      <div className="container mx-auto flex justify-between items-center px-4 sm:px-6 lg:px-8 text-white w-[80%]">
        <div className="flex items-center space-x-2">
          <i className="fas fa-info-circle"></i>
          <span>INFO: Covid-19 information update!</span>
        </div>
        <div className="flex items-center space-x-4">
          <a href="mailto:hello@sureshift.in" className="flex items-center space-x-1">
            <i className="fas fa-envelope"></i>
            <span>info@sureshift.in</span>
          </a>
          <a href="tel:+919073291732" className="flex items-center space-x-1">
            <i className="fas fa-phone"></i>
            <span>90 732 91 732</span>
          </a>
          <div className="flex items-center space-x-1">
            <i className="fas fa-clock"></i>
            <span>(Mon to Sun) 24 X 7</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
