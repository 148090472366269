import React, { useState } from 'react';
import WhatsAppButton from "./WhatsAppButton";
import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from 'axios';

export default function Home() {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const options = [
        { id: 1, label: "Inquiry" },
        { id: 2, label: "Feedback" },
        { id: 3, label: "Support" },
    ];

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleReset = () => {
        setFormData({ name: "", email: "", message: "" });
        setSelectedOption(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading

        const updatedFormData = {
            ...formData,
            purpose: options.find(option => option.id === selectedOption)?.label || ''
        };

        try {
            await axios.post('/contact', updatedFormData);
            alert('User data submitted successfully');
            handleReset(); // Reset the form data after successful submission
        } catch (error) {
            console.error('Error submitting user data:', error);
            alert('Failed to submit user data');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <>
            <Infobar />
            <InfobarMob />
            <Navbar />
            <WhatsAppButton />
            <div className="container mx-auto px-6 py-12">
                <div className="flex flex-wrap lg:flex-nowrap bg-white rounded-lg shadow-lg overflow-hidden">
                    {/* Form Section */}
                    <div className="w-full lg:w-2/3 text-[#D81F47] p-8 lg:p-12">
                        <h2 className="lg:text-4xl text-2xl font-bold mb-6">How Can We Help You?</h2>
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div className="flex space-x-4">
                                <div className="w-1/2">
                                    <label className="block text-black font-medium mb-2" htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-3 border border-gray rounded-md text-black placeholder-black-300 focus:ring-2 focus:ring-white focus:outline-none"
                                        placeholder="Your Name"
                                        required
                                    />
                                </div>
                                <div className="w-1/2">
                                    <label className="block text-black font-medium mb-2" htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-3 border border-gray rounded-md text-black placeholder-black-300 focus:ring-2 focus:ring-white focus:outline-none"
                                        placeholder="Your Email"
                                        required
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-black font-medium mb-2" htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="w-full p-3 border border-gray rounded-md text-black placeholder-black-300 focus:ring-2 focus:ring-white focus:outline-none"
                                    placeholder="Your Message"
                                    required
                                ></textarea>
                            </div>
                            <div>
                                <label className="block text-black font-medium mb-2">Purpose</label>
                                <select
                                    value={selectedOption || ""}
                                    onChange={(e) => setSelectedOption(Number(e.target.value))}
                                    className="w-full p-3 border border-gray rounded-md text-black placeholder-black-300 placeholder-bg-black focus:ring-2 focus:ring-white focus:outline-none"
                                    required
                                >
                                    <option value="" disabled >Select purpose</option>
                                    {options.map(option => (
                                        <option key={option.id} value={option.id}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-[#D81F47] text-white font-bold py-3 rounded-md transition duration-300"
                                disabled={isLoading}
                            >
                                {isLoading ? "Submitting..." : "Submit"}
                            </button>
                        </form>
                    </div>

                    {/* Contact Details Section */}
                    <div className="w-full lg:w-1/3 bg-gray-100 p-8 lg:p-12">
                        <h2 className="text-3xl font-bold text-[#D81F47] mb-6">Visit Team Sure Shift</h2>
                        <p className="text-gray-700 mb-6">
                            Call us for Booking. We are available 24/7 to assist you with your queries.
                        </p>
                        <div className="space-y-4">
                            <div className="flex items-center space-x-4">
                                <span className="text-[#D81F47] text-xl font-bold">Contact No:</span>
                                <a href="tel:8669527200" className="text-[#D81F47] hover:underline">90 732 91 732</a>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-xl font-bold text-[#D81F47]">Address:</h3>
                                <p className="text-gray-700">Gopal Nagar Extn,<br/> New Delhi, 110043</p>
                            </div>
                            {/* Social Media Section */}
                            <div className="mt-6">
                                <p className="text-[#D81F47] text-sm">Follow us on social media:</p>
                                <div className="flex space-x-4 mt-4">
                                    <a href="https://www.facebook.com/profile.php?id=61559606034810&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-[#D81F47] hover:text-red-400">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="https://x.com/Sure_Shift?t=5t-89G8ENh39QWZsvoifjw&s=09" target="_blank" rel="noopener noreferrer" className="text-[#D81F47] hover:text-red-400">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="https://www.instagram.com/sure.shift/profilecard/?igsh=MXJldDEwZGpndDB6Zw%3D%3D" target="_blank" rel="noopener noreferrer" className="text-[#D81F47] hover:text-red-400">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/sureshift/" target="_blank" rel="noopener noreferrer" className="text-[#D81F47] hover:text-red-400">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:-mb-[120px] -mb-[50px] h-[400px]">
                <iframe
                    title="Location map of Gopal Nagar Extension, New Delhi"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7004.910594148318!2d76.95840239273868!3d28.61611345269459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0fccba63cc3d%3A0x2fea0e016d527c84!2sGopal%20Nagar%20Extn%2C%20Sarswati%20Enclave%2C%20Gopal%20Nagar%20Extension%2C%20Haibutpur%2C%20Delhi%2C%20110043!5e0!3m2!1sen!2sin!4v1734872380196!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <Footer />
        </>
    );
}
