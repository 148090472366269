import React, { useState, useEffect, useCallback } from 'react';
import Infobar from './Infobar';
import InfobarMob from './Infobar_mob';
import Navbar from './Navbar';
import Footer from './Footer';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export default function OrderTracking({ orderId }) {
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const awbNumber = searchParams.get('awb');

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.post('/completeInfo', { order_id: awbNumber });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [awbNumber]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Enquiry Received':
        return 'text-gray-500';
      case 'Survey Initiated':
        return 'text-blue-400';
      case 'Survey Done':
        return 'text-blue-500';
      case 'Quotation Sent':
        return 'text-yellow-400';
      case 'Quotation Approved':
        return 'text-yellow-500';
      case 'CFR Generated':
        return 'text-green-400';
      case 'On the way for Pickup':
        return 'text-indigo-400';
      case 'Pickup Point Reached':
        return 'text-indigo-500';
      case 'Packing Started':
        return 'text-purple-400';
      case 'Packing Finished':
        return 'text-purple-500';
      case 'Loading Started':
        return 'text-pink-400';
      case 'Loading Finished':
        return 'text-pink-500';
      case 'In Transit':
        return 'text-orange-400';
      case 'Location A':
        return 'text-orange-500';
      case 'Location B':
        return 'text-teal-400';
      case 'Location C':
        return 'text-teal-500';
      case 'On the way for Delivery':
        return 'text-cyan-400';
      case 'Delivery Point Reached':
        return 'text-cyan-500';
      case 'Unloading Started':
        return 'text-red-400';
      case 'Unloading Finished':
        return 'text-red-500';
      case 'Unpacking Started':
        return 'text-lime-400';
      case 'Unpacking Finished':
        return 'text-lime-500';
      case 'Delivered':
        return 'text-green-500'; // Color for "Order Delivered"
      default:
        return 'text-gray-400'; // Default color for unknown statuses
    }
  };
  

  const renderField = (label, value) => {
    if (value != null && value !== '') {
      return (
        <p className="mb-2">
          <strong>{label}:</strong> {value}
        </p>
      );
    }
    return null;
  };

  return (
    <div>
      <Infobar />
      <InfobarMob />
      <Navbar />
      <div className="mx-auto flex flex-col justify-center items-center mt-10 p-6 bg-white rounded-md shadow-md shadow-[gray] lg:w-[60%] w-[80%] -mb-[60px]">
        <h2 className="text-2xl font-bold mb-6 text-center">Order Tracking Info</h2>
        <div>
          {users.map(user => (
            <div key={user.id} className="bg-white shadow-lg shadow-[gray] rounded-lg p-4 mb-4">
              <h3 className="text-lg font-bold mb-2">{user.name}</h3>
              {renderField('Enquiry No', user.order_id)}
              {renderField('Phone', user.phone)}
              {renderField('Email', user.email)}
              {renderField('Purpose', user.purpose)}
              {renderField('Pickup Address', user.pickup_address)}
              {renderField('Drop Address', user.drop_address)}
              {renderField('Pickup Date', user.pickup_date)}
              {renderField('Pickup Time', user.pickup_time)}
              {renderField('Apartment Size', user.apartment_size)}
              {renderField('Company Name', user.company_name)}
              {renderField('Car Model', user.car_model)}
              {renderField('Storage Type', user.storage_type)}
              {renderField('Material Type', user.material_type)}
              {renderField('Vehicle Type', user.vehicle_type)}
              {renderField('Bike Model', user.bikemodel)} 
              {renderField('Parcel Weight', user.parcel_weight)} 
              {renderField('Truck Type', user.trucktype)}
              {renderField('Last Mile Material Type', user.last_mile_material_type)}
              {renderField('Measurement', user.measurement)} 
              {renderField('Shipment Value', user.shipment_value)} 
              {renderField('Content', user.content)}
              <p className={`mb-2 ${getStatusColor(user.status)}`}>
                <strong>Status:</strong> {user.status || 'Enquiry Requested'}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
