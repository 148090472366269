import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const CompleteInfoModal = ({ isVisible, onClose, orderId }) => {
    const [users, setUsers] = useState([]);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.post('/completeInfo', { order_id: orderId });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [orderId]);

    useEffect(() => {
        if (isVisible) {
            fetchUsers();
        }
    }, [isVisible, fetchUsers]);

    if (!isVisible) return null;

    // Utility function to format the date
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Utility function to render fields if not null or empty
    const renderField = (label, value) => {
        if (value != null && value !== '') {
            return (
                <p><span className="font-semibold">{label}:</span> {value}</p>
            );
        }
        return null;
    };

    return (
        <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center'>
            <div className="mx-auto mt-10 p-6 bg-white rounded-md shadow-md w-[30%] -mb-[60px]">
                <h2 className="text-2xl font-bold mb-6 text-center">User Info</h2>
                <button onClick={onClose} className="bg-blue-500 text-white p-2 rounded-md mb-4">
                    Back to Page
                </button>
                <div className="flex justify-center items-center">
                    {users.map(user => (
                        <div key={user.id} className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-lg font-bold mb-2">{user.name}</h3>
                            {renderField('Email', user.email)}
                            {renderField('Phone', user.phone)}
                            {renderField('Pickup Date', user.pickup_date && formatDate(user.pickup_date))}
                            {renderField('Pickup Time', user.pickup_time)}
                            {renderField('Pickup Address', user.pickup_address)}
                            {renderField('Drop Address', user.drop_address)}
                            {renderField('Enquiry No', user.order_id)}
                            {renderField('Status', user.status == null ? 'Order Requested' : user.status)}
                            {renderField('Purpose', user.purpose)}
                            {renderField('Apartment Size', user.apartment_size)}
                            {renderField('Company Name', user.company_name)}
                            {renderField('Car Model', user.car_model)}
                            {renderField('Storage Type', user.storage_type)}
                            {renderField('Material Type', user.material_type)}
                            {renderField('Vehicle Type', user.vehicle_type)}
                            {renderField('Bike Model', user.bikemodel)}
                            {renderField('Parcel Weight', user.parcel_weight)}
                            {renderField('Truck Type', user.trucktype)}
                            {renderField('Last Mile Material Type', user.last_mile_material_type)}
                            {renderField('Measurement', user.measurement)}
                            {renderField('Shipment Value', user.shipment_value)}
                            {renderField('Content', user.content)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompleteInfoModal;
