import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Components/context/AuthContext'; // Ensure AuthProvider is imported
import PrivateRoute from './Components/PrivateRoute'; // Ensure PrivateRoute is imported
import Home from "./Components/Home";
import AdminDashboard from './Components/AdminDashboard';
import LoginForm from './Components/LoginForm';
import OrderTracking from './Components/OrderTracking';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import PaymentGateway from './Components/PaymentGateway';

//new for blog
import AdminBlogDashboard from './Components/AdminBlogDashboard';
import BlogPage from './Components/BlogPage';
import BlogDetail from './Components/BlogDetail.js';
import './App.css'; // Import the global CSS

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="container mx-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/order_track" element={<OrderTracking/>} />
            <Route path="/about_us" element={<AboutUs/>} />
            <Route path="/blog" element={<BlogPage/>} />
            <Route path="/blog-detail/:id" element={<BlogDetail />} />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/payment_gateway" element={<PaymentGateway />} />
            <Route path="/admin-dashboard" element={<PrivateRoute element={<AdminDashboard />} />} />
            <Route path="/admin-blogdashboard" element={<PrivateRoute element={<AdminBlogDashboard />} />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;

