import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

const options = [
    { id: 'household', label: 'Household Moving', icon: '🚚' },
    { id: 'office', label: 'Office Moving', icon: '🏢' },
    { id: 'international', label: 'International Moving', icon: '🌍' },
    { id: 'car', label: 'Car Moving', icon: '🚗' },
    { id: 'bike', label: 'Bike Moving', icon: '🏍️' },
    { id: 'secure', label: 'Secure Storage', icon: '🏠' },
    { id: 'fine_arts', label: 'Fine Arts & Sculptures Moving', icon: '🎨' },
    { id: 'commercial', label: 'Commercial Moving', icon: '🏭' },
    { id: 'courier', label: 'Courier', icon: '📦' },
    { id: 'truck', label: 'Truck Rental', icon: '🚛' },
    { id: 'last_mile', label: 'Last Mile Delivery', icon: '🚚' },
    { id: 'odc_consignment', label: 'ODC Consignment', icon: '🚜' },
];

const apartmentSizes = [
    { value: 'few_items', label: 'Few Items' },
    { value: 'studio_apartment', label: 'Studio Apartment' },
    { value: '1_bhk', label: '1 BHK' },
    { value: '2_bhk', label: '2 BHK' },
    { value: '3_bhk', label: '3 BHK' },
    { value: '4_bhk', label: '4 BHK' },
    { value: '4_plus_bhk', label: '4+ BHK' }
];
const storageTypes = [
    { value: 'household', label: 'Household Storage' },
    { value: 'car', label: 'Car Storage' },
    { value: 'two_wheeler', label: 'Two Wheeler Storage' },
    { value: 'office_goods', label: 'Office Goods Storage' },
    { value: 'documents', label: 'Documents Storage' },
    { value: 'commercial', label: 'Commercial Storage' },
    { value: 'warehousing_space', label: 'Warehousing Space Storage' },
];
const vehicleTypes = [
    { value: '2_wheeler', label: '2 wheeler' },
    { value: '3_wheeler', label: '3 wheeler' },
    { value: 'tata_ACE', label: 'Tata ACE' },
];

const truckType = [
    { value: '3_wheeler', label: '3 wheeler' },
    { value: 'tata_ACE', label: 'Tata ACE' },
    { value: 'mahindra_bolero_pickup', label: 'Mahindra Bolero Pickup' },
    { value: 'ashok_leyland_dost', label: 'Ashok Leyland Dost' },
    { value: '14_FT_canter', label: '14 FT Canter' },
    { value: '17_FT_canter', label: '17 FT Canter' },
    { value: '19_FT_canter', label: '19 FT Canter' },
    { value: '22_FT_canter', label: '22 FT Canter' },
    { value: '32_FT_SXL', label: '32 FT SXL' },
    { value: '32_FT_MXL', label: '32 FT MXL' },
];

const last_mile_material_type = [
    { value: 'e-commerce_packets', label: 'E-Commerce Packets' },
    { value: 'grocery', label: 'Grocery' },
    { value: 'food_items', label: 'Food Items' },
    { value: 'documents', label: 'Documents' },
    { value: 'others', label: 'Others' },
];

const contentOptions = [
    { value: 'artificial_jewellery', label: 'Artificial Jewellery' },
    { value: 'auto_machine_parts', label: 'Auto Machine Parts' },
    { value: 'boots', label: 'Boots' },
    { value: 'cable_wires', label: 'Cable/Wires' },
    { value: 'camera', label: 'Camera' },
    { value: 'cd_pendrive', label: 'CD/Pendrive' },
    { value: 'charger_set', label: 'Charger Set' },
    { value: 'cheque_book', label: 'Cheque Book' },
    { value: 'chocolates', label: 'Chocolates' },
    { value: 'clothes', label: 'Clothes' },
    { value: 'computer_peripherals', label: 'Computer Peripherals' },
    { value: 'corporate_gifts', label: 'Corporate Gifts' },
    { value: 'credit_debit_cards', label: 'Credit/Debit Cards' },
    { value: 'documents', label: 'Documents' },
    { value: 'dry_fruits', label: 'Dry Fruits' },
    { value: 'electronic_items', label: 'Electronic Items' },
    { value: 'food_items_packaged', label: 'Food Items (Packaged)' },
    { value: 'furniture', label: 'Furniture' },
    { value: 'gadget_cover', label: 'Gadget Cover' },
    { value: 'headphone', label: 'Headphone' },
    { value: 'home_appliances', label: 'Home Appliances' },
    { value: 'household_goods', label: 'Household Goods' },
    { value: 'laptop', label: 'Laptop' },
    { value: 'laptop_bag', label: 'Laptop Bag' },
    { value: 'hand_bag', label: 'Hand Bag' },
    { value: 'led_lights', label: 'LED Lights' },
    { value: 'luggage', label: 'Luggage' },
    { value: 'medical_equipment', label: 'Medical Equipment' },
    { value: 'medicine', label: 'Medicine' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'painting', label: 'Painting' },
    { value: 'passport', label: 'Passport' },
    { value: 'plastic_items', label: 'Plastic Items' },
    { value: 'promotional_material', label: 'Promotional Material (Paper)' },
    { value: 'seeds', label: 'Seeds' },
    { value: 'shoes', label: 'Shoes' },
    { value: 'sim_cards', label: 'SIM Cards' },
    { value: 'spices', label: 'Spices' },
    { value: 'stationery', label: 'Stationery' },
    { value: 'stitched_garment', label: 'Stitched Garment' },
    { value: 'sweets', label: 'Sweets' },
    { value: 'toys', label: 'Toys' },
    { value: 'constitched_clothes_of_fabric', label: 'Constitched Clothes Of Fabric' },
    { value: 'other', label: 'Other' },
];


const UserForm2 = () => {
    const [selectedOption, setSelectedOption] = useState('car');
    const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering dropdown
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown open/close

    // Filter options based on the search term
    const filteredOptions = contentOptions.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        pickup_date: '',
        pickup_time: '',
        pickup_address: '',
        drop_address: '',
        purpose: '',
        apartmentSize: '',
        companyName: '',
        carModel: '',
        storageType: '',
        materialType: '',
        vehicleType: '',
        truckType: '',
        parcel_weight: '',
        bikeModel: '',
        last_mile_material_type: '',
        measurement: '',
        shipment_value: '',
        content: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const recaptchaRef = useRef(null); // Create a reference to the reCAPTCHA component
    const autocompletePickupRef = useRef(null); // Ref for Pickup Address Autocomplete
    const autocompleteDropRef = useRef(null); // Ref for Drop Address Autocomplete

    const handleOptionClick = (id) => {
        setSelectedOption(id);
    };

    // Handle input changes (measurement and content fields)
    const handleChange = (e) => {
        let value = e.target.value;

        // Format the 'measurement' field
        if (e.target.name === 'measurement') {
            value = value.replace(/[^0-9X]/g, ''); // Allow digits and 'X'
            value = value.replace(/(\d{2})(?=\d)/g, '$1X'); // Insert 'X' after 2 digits
        }

        // Update form data for other fields
        setFormData({
            ...formData,
            [e.target.name]: value,
        });

        // If typing in 'content', open the dropdown or filter options
        if (e.target.name === 'content') {
            setSearchTerm(value); // Update search term for filtering
            setIsDropdownOpen(value !== ""); // Open the dropdown when there is any text
        }
    };

    // Handle option selection
    const handleSelectOption = (option) => {
        setFormData({ ...formData, content: option.label });
        setSearchTerm(""); // Clear search term after selection
        setIsDropdownOpen(false); // Close the dropdown after selection
    };

    // Handle input focus (open dropdown on focus)
    const handleInputFocus = () => {
        setIsDropdownOpen(true); // Open dropdown when the input is focused
    };

    // Handle input blur (close dropdown when input loses focus)
    const handleInputBlur = () => {
        setTimeout(() => setIsDropdownOpen(false), 200); // Delay to allow click event on option
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!captchaToken) {
            alert("Please complete the CAPTCHA");
            setIsLoading(false);
            return;
        }

        const updatedFormData = {
            ...formData,
            purpose: options.find(option => option.id === selectedOption)?.label || '',
            'g-recaptcha-response': captchaToken
        };

        try {
            await axios.post('/user', updatedFormData);
            alert('User data submitted successfully');
            handleReset();
        } catch (error) {
            console.error('Error submitting user data:', error);
            alert('Failed to submit user data');
        } finally {
            setIsLoading(false);
            setCaptchaToken(null);
        }
    };

    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            pickup_date: '',
            pickup_time: '',
            pickup_address: '',
            drop_address: '',
            purpose: '',
            apartmentSize: '',
            companyName: '',
            carModel: '',
            storageType: '',
            materialType: '',
            vehicleType: '',
            bikeModel: '',
            parcel_weight: '',
            truckType: '',
            last_mile_material_type: '',
            measurement: '',
            shipment_value: '',
            content: '',
        });
        setCaptchaToken(null);
        recaptchaRef.current.reset(); // Reset the reCAPTCHA widget
    };

    const onCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleAddressChange = (addressType, place) => {
        setFormData({
            ...formData,
            [addressType]: place.formatted_address
        });
    };

    const renderFormFields = () => {
        switch (selectedOption) {
            case 'household':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Apartment Size</label>
                                <select
                                    name="apartmentSize" // Set the name to match formData key
                                    value={formData.apartmentSize} // Bind the select to apartmentSize in formData
                                    onChange={handleChange} // Update formData on change
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                >
                                    <option value="">Select Apartment Size</option>
                                    {apartmentSizes.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'office':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Company Name'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'international':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'car':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Car Model</label>
                                <input
                                    type="text"
                                    name="carModel"
                                    value={formData.carModel}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Car Model'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'bike':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Bike Model</label>
                                <input
                                    type="text"
                                    name="bikeModel"
                                    value={formData.bikeModel}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Bike Model'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'secure':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Storage Type</label>
                                <select
                                    name="storageType" // Set the name to match formData key
                                    value={formData.storageType} // Bind the select to apartmentSize in formData
                                    onChange={handleChange} // Update formData on change
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                >
                                    <option value="">Select Storage Type</option>
                                    {storageTypes.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>

                );
            case 'fine_arts':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'commercial':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Company Name'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Material Type</label>
                                <input
                                    type="text"
                                    name="materialType"
                                    value={formData.materialType}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Material Type'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'courier':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Parcel Weight</label>
                                <input
                                    type="text"
                                    name="parcel_weight"
                                    value={formData.parcel_weight}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Parcel Weight in grams (use digits only)'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Measurements (cm) - L x W x H</label>
                                <input
                                    type="text"
                                    name="measurement"
                                    value={formData.measurement}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder="e.g., 10x20x30"
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Shipment Value</label>
                                <input
                                    type="text"
                                    name="shipment_value"
                                    value={formData.shipment_value}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Shipment Value'
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="relative w-full md:w-1/3 mb-4">
                                {/* Content Dropdown with Search */}
                                <label className="block text-gray-700">Select Content</label>
                                <input
                                    type="text"
                                    name="content"
                                    placeholder="Search or Select Content"
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    value={formData.content} // Controlled value from formData
                                    onChange={handleChange} // Handle changes to input
                                    onFocus={handleInputFocus} // Open dropdown when input is focused
                                    onBlur={handleInputBlur} // Close dropdown when input loses focus
                                />

                                {/* Dropdown Displaying Filtered or All Options */}
                                {isDropdownOpen && (
                                    <ul className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-md max-h-40 overflow-y-auto">
                                        {filteredOptions.length > 0 ? (
                                            filteredOptions.map((option) => (
                                                <li
                                                    key={option.value}
                                                    onClick={() => handleSelectOption(option)} // Select option
                                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                                >
                                                    {option.label}
                                                </li>
                                            ))
                                        ) : (
                                            <li className="p-2 text-gray-500">No options found</li>
                                        )}
                                    </ul>
                                )}
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'truck':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Company Name'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Truck Type</label>
                                <select
                                    name="truckType" // Set the name to match formData key
                                    value={formData.truckType} // Bind the select to apartmentSize in formData
                                    onChange={handleChange} // Update formData on change
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                >
                                    <option value="">Select Truck Type</option>
                                    {truckType.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'last_mile':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Company Name'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Material Type</label>
                                <select
                                    name="last_mile_material_type" // Set the name to match formData key
                                    value={formData.last_mile_material_type} // Bind the select to apartmentSize in formData
                                    onChange={handleChange} // Update formData on change
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                >
                                    <option value="">Select Material Type</option>
                                    {last_mile_material_type.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Vehicle Type</label>
                                <select
                                    name="vehicleType" // Set the name to match formData key
                                    value={formData.vehicleType} // Bind the select to apartmentSize in formData
                                    onChange={handleChange} // Update formData on change
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                >
                                    <option value="">Select Vehicle Type</option>
                                    {vehicleTypes.map(size => (
                                        <option key={size.value} value={size.value}>
                                            {size.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            case 'odc_consignment':
                return (
                    <>
                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Company Name'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Material Type</label>
                                <input
                                    type="text"
                                    name="materialType"
                                    value={formData.materialType}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md"
                                    required
                                    placeholder='Enter Material Type'
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Date</label>
                                <input
                                    type="date"
                                    name="pickup_date"
                                    value={formData.pickup_date}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex flex-wrap mb-4">
                            <div className="w-full md:w-1/3 mb-4">
                                <label className="block text-gray-700">Pickup Time</label>
                                <input
                                    type="time"
                                    name="pickup_time"
                                    value={formData.pickup_time}
                                    onChange={handleChange}
                                    className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                    required
                                />
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Pickup Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="pickup_address"
                                        value={formData.pickup_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>

                            <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                                <label className="block text-gray-700">Drop Address</label>
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                                    onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                                >
                                    <input
                                        type="text"
                                        name="drop_address"
                                        value={formData.drop_address}
                                        onChange={handleChange}
                                        className="mt-2 p-2 block w-full border rounded-md"
                                        required
                                    />
                                </Autocomplete>
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const renderForm = () => {
        return (

            <form onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-6 text-center">
                    Get a free <span className='text-[red]'>{options.find(option => option.id === selectedOption)?.label}</span> quote
                </h2>

                {/* Common Fields */}
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/3 mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md"
                            required
                            placeholder='Enter Name'
                        />
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md"
                            required
                            placeholder='Enter Phone No.'
                        />
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md"
                            required
                            placeholder='Enter Email'
                        />
                    </div>
                </div>

                {/* <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/3 mb-4">
                        <label className="block text-gray-700">Pickup Date</label>
                        <input
                            type="date"
                            name="pickup_date"
                            value={formData.pickup_date}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>

                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Pickup Time</label>
                        <input
                            type="time"
                            name="pickup_time"
                            value={formData.pickup_time}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Pickup Address</label>
                        <Autocomplete
                            onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                            onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                        >
                            <input
                                type="text"
                                name="pickup_address"
                                value={formData.pickup_address}
                                onChange={handleChange}
                                className="mt-2 p-2 block w-full border rounded-md"
                                required
                            />
                        </Autocomplete>
                    </div>
                </div> */}

                {/* Render dynamic form fields */}
                {renderFormFields()}

                {/* reCAPTCHA */}
                <div className="flex justify-center mb-4">
                    <ReCAPTCHA
                        sitekey="6LcNBTUqAAAAANEVacfo2ApjLvEImEf8OeSlygJE" // Replace with your actual site key
                        onChange={onCaptchaChange}
                        ref={recaptchaRef} // Attach the ref
                    />
                </div>

                {/* Submit and Reset Buttons */}
                <div className="flex flex-wrap mb-4 justify-center">
                    <div className="w-full md:w-1/3 mb-4">
                        <button
                            type="submit"
                            className={`w-full p-2 rounded-md ${isLoading ? 'bg-gray-500' : 'bg-[#0E2334]'} text-white`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <button
                            type="button"
                            onClick={handleReset}
                            className="w-full bg-red-500 text-white p-2 rounded-md"
                            disabled={isLoading}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        );
    };


    return (
        <LoadScript
            googleMapsApiKey="AIzaSyC5WzOSNJzkMATaZOnBUCV_ryvr4RsNQMY" // Replace with your actual API key
            libraries={['places']}
        >
            <div className="w-full flex flex-col items-center lg:mt-[50px] mb-[100px]">
                <div className="w-[85%] lg:w-[50%] flex flex-wrap lg:flex-row flex-row justify-center mb-6">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`cursor-pointer p-4 lg:w-[113px] w-[90px] flex flex-col text-center items-center mb-2 justify-center rounded-md shadow-md me-2 border ${selectedOption === option.id ? 'bg-red-100 border-red-500' : 'bg-white border-gray-300'}`}
                        >
                            <div className="text-2xl mb-2">{option.icon}</div>
                            <div className={`text-xs lg:text-sm font-bold ${selectedOption === option.id ? 'text-red-500' : 'text-gray-700'}`}>
                                {option.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-[90%] lg:w-[80%] p-6 rounded-md shadow-md bg-white">
                    {renderForm()}
                </div>
            </div>
        </LoadScript>
    );
};

export default UserForm2;
