import React, { useState } from "react";
import WhatsAppButton from "./WhatsAppButton";
import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";
import securePayment from './img/secure payment.png';
import instruction from './img/instruction.jpg';
import logo from './img/favicon.jpeg';

const PaymentGateway = () => {
  const [formData, setFormData] = useState({
    enquiryNo: "",
    mobile: "",
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const isLoaded = await loadRazorpayScript();
    if (!isLoaded) {
      alert("Failed to load Razorpay SDK. Please check your connection.");
      return;
    }

    const options = {
      key: "rzp_live_ZvW7DYreJ3jN0P", // Replace with your Razorpay key
      amount: formData.amount * 100, // Amount in paise (multiply by 100 for INR)
      currency: "INR",
      name: "SureShift",
      description: "Secure Payment",
      image: { logo }, // Replace with your logo URL
      handler: function (response) {
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
        console.log("Payment response:", response);
        // Optionally, send the payment details to your server for verification
      },
      prefill: {
        name: formData.enquiryNo,
        contact: formData.mobile,
      },
      notes: {
        enquiryNo: formData.enquiryNo, // Add custom field here
      },
      theme: {
        color: "#D81F47",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      alert(`Payment failed: ${response.error.description}`);
      console.log("Payment failed:", response.error);
    });
    rzp.open();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.amount || formData.amount <= 0) {
      alert("Please enter a valid amount.");
      return;
    }
    handlePayment();
  };

  return (
    <>
      <Infobar />
      <InfobarMob />
      <Navbar />
      <WhatsAppButton />
      <div className="min-h-screen bg-gradient-to-b from-white via-gray-50 to-gray-100 py-10 px-5 lg:px-20 lg:-mb-[120px] -mb-[50px] lg:pb-[100px] pb-[50px] ">
        {/* Header Section */}
        <div className='w-[100%] flex justify-center lg:mt-6 mt-0'>
          <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 lg:w-[80%] lg:mb-[100px]">
            <div className="lg:w-1/2 text-center lg:text-left w-[350px]">
              <h1 className="lg:text-5xl text-3xl font-bold mb-4">Secure Your Move with Our Easy Payment Process </h1>
              <p className="lg:text-lg text-md mb-6 text-[#7f7e7e] font-medium">Confirm your booking with SureShift by filling out the details below and
                proceeding to our secure payment gateway. Your data and transactions are
                fully protected.</p>
            </div>
            <div className="lg:w-[500px] flex justify-center lg:justify-end mt-2">
              <img src={securePayment} alt="Delivery Service" className="w-full lg:w-[]" />
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="bg-white rounded-xl shadow-lg p-10 max-w-4xl mx-auto mt-[50px]">
          <h2 className="text-3xl font-bold text-center text-[#D81F47] mb-8">
            Checkout Form
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <input
                type="text"
                name="enquiryNo"
                placeholder="Enquiry Number"
                value={formData.enquiryNo}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded-lg px-5 py-3 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#D81F47]"
              />
              <input
                type="number"
                name="mobile"
                placeholder="Contact Number"
                value={formData.mobile}
                onChange={handleChange}
                required
                className="border border-gray-300 rounded-lg px-5 py-3 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#D81F47]"
              />
            </div>
            <input
              type="number"
              name="amount"
              placeholder="Amount to Pay (INR)"
              value={formData.amount}
              onChange={handleChange}
              required
              className="border border-gray-300 rounded-lg px-5 py-3 w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-[#D81F47]"
            />
            <button
              type="submit"
              className="bg-[#D81F47] text-white font-semibold rounded-lg px-6 py-4 w-full shadow-lg hover:bg-[#FFCE7C] hover:text-[#D81F47] transition-all duration-300"
            >
              Proceed to Payment
            </button>
          </form>
        </div>


        {/* Payment Instructions Section */}
        <div className="w-[100%] flex justify-center lg:mt-[100px] mt-[50px] m-auto">
          <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-12 lg:w-[80%] lg:mb-[100px]">
            {/* Text Content */}
            <div className="lg:w-1/2 text-center lg:text-left w-[350px]">
              <h3 className="lg:text-4xl text-3xl font-bold mb-6 text-[#D81F47]">
                Payment Instructions
              </h3>
              <ul className="text-md text-[#7f7e7e] font-medium space-y-4 list-disc list-inside text-left w-[85%] m-auto">
                <li>Fill in the required details in the form above.</li>
                <li>Enter the agreed amount in the "Amount to Pay" field.</li>
                <li>Click on the "Proceed to Payment" button to access the secure gateway.</li>
                <li>Complete the transaction using your preferred payment method.</li>
                <li>Upon success, you will receive a confirmation email with your payment receipt and booking details.</li>
              </ul>
            </div>

            {/* Image Content */}
            <div className="lg:w-[500px] flex justify-center lg:justify-end mt-6 lg:mt-0 ">
              <img
                src={instruction} // Replace with your image URL
                alt="Payment Instructions Illustration"
                className="w-full lg:w-auto rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>

        {/* Security Assurance */}
        <div className="bg-white p-8 rounded-xl shadow-lg lg:w-[80%] w-[100%] mt-[50px] lg:mt-0 m-auto">
          <h3 className="text-2xl font-bold text-[#D81F47]">
            Security Assurance
          </h3>
          <p className="text-gray-600 mt-4">
            We process payments through a secure gateway with encryption
            protocols to ensure your personal and financial data is fully
            protected.
          </p>
        </div>

        {/* Contact Info */}
        <div className="bg-white p-8 rounded-xl shadow-lg text-center m-auto lg:w-[80%] w-[100%] lg:mt-[50px] mt-[50px]">
          <h3 className="text-2xl font-bold text-[#D81F47]">
            Need Help?
          </h3>
          <p className="text-gray-600 mt-4">
            Reach out to our support team at {" "}
            <span className="font-bold text-[#D81F47]">
              info@sureshift.in
            </span>{" "}
            or call us at {" "}
            <span className="font-bold text-[#D81F47]">
              +91-9073291732
            </span>
            . We’re available 24/7 to assist you.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentGateway;
